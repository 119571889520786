<template>
  <div class="full-gift">
    <div class="search-bar">
      <span class="label">活动：</span>
      <el-input
        size="small"
        placeholder="请输入活动ID/名称"
        class="input"
        v-model="search.activityNoOrName"
      ></el-input>

      <span class="label">创建日期：</span>
      <el-date-picker
        type="date"
        placeholder="选择日期"
        value-format="yyyy-MM-dd"
        class="date-picker"
        size="small"
        v-model="createTime"
      >
      </el-date-picker>

      <span class="label">活动状态：</span>
      <el-select v-model="search.isDisable" placeholder="请选择状态" size="small" class="select">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <span class="label">活动渠道：</span>
      <KeySelect req-key="source_from" :val.sync="search.activityChannel" />

      <span class="label">适用城市：</span>
      <CitySelect :select-val.sync="search.cityId" />

      <span class="label">适用品牌：</span>
      <ProductBrandSelect :select-val.sync="search.productBrandId" />

      <span class="label">活动开始时间：</span>
      <el-date-picker
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="small"
        class="date-picker"
        v-model="activityStartTimeArr"
      >
      </el-date-picker>

      <span class="label">活动结束时间：</span>
      <el-date-picker
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="small"
        class="date-picker"
        v-model="activityEndTimeArr"
      >
      </el-date-picker>

      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList">
        查询
      </el-button>
      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch">
        重置
      </el-button>
    </div>

    <el-button
      type="primary"
      size="small"
      style="margin: 20px 0"
      icon="el-icon-plus"
      @click="processItem({}, 1)"
    >
      新增活动
    </el-button>

    <el-table
      :data="tableData"
      style="width: 100%"
      class="table-ex"
      border
      :header-cell-style="{ 'background-color': '#f5f7fa' }"
      size="small"
    >
      <!-- <el-table-column prop="activityId" label="活动ID"> </el-table-column> -->
      <el-table-column prop="activityNo" label="活动ID"> </el-table-column>
      <el-table-column prop="activityName" label="活动名称"> </el-table-column>
      <el-table-column prop="createUserName" label="创建人"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column prop="activityStartTime" label="活动开始时间"> </el-table-column>
      <el-table-column prop="activityEndTime" label="活动结束时间"> </el-table-column>
      <el-table-column prop="activityCity" label="适用城市"> </el-table-column>
      <el-table-column prop="activityProduct" label="适用品牌"> </el-table-column>
      <el-table-column prop="activityChannel" label="活动渠道"> </el-table-column>
      <el-table-column prop="quota" label="活动总次数"> </el-table-column>
      <el-table-column prop="takeLimit" label="单人最大参与次数"> </el-table-column>
      <el-table-column prop="participateNum" label="已参与"> </el-table-column>

      <el-table-column label="启用状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isDisable == 1 ? 'danger' : 'success'">
            {{ scope.row.isDisable == 1 ? '停用' : '启用' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="processItem(scope.row, 2)"
            v-if="scope.row.isDisable == 1 && scope.row.participateNum <= 0"
          >
            编辑
          </el-button>

          <el-button type="text" size="small" @click="processItem(scope.row, 3)">
            {{ scope.row.isDisable == 1 ? '启用' : '停用' }}
          </el-button>

          <el-button type="text" size="small" @click="processItem(scope.row, 4)"> 详情 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination
        background
        layout="total,prev,pager,next,sizes"
        :total="total"
        :page-sizes="[10, 50, 100, 200]"
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="pageNumber"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CitySelect from './components/CitySelect.vue'
import ProductBrandSelect from './components/ProductBrandSelect.vue'
import { getFullGiftList, updateFullGiftItemStatus } from '@/api/fullGift'

export default {
  components: {
    CitySelect,
    ProductBrandSelect,
  },
  data() {
    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {
        activityNoOrName: '',
        isDisable: '',
        activityChannel: '',
        cityId: '',
        productBrandId: '',
        activityId: '',
      },
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '启用',
        },
        {
          value: 1,
          label: '停用',
        },
      ],
      createTime: '',
      activityStartTimeArr: [],
      activityEndTimeArr: [],
    }
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search }

      if (this.createTime) {
        obj.createTimeStart = `${this.createTime} 00:00`
        obj.createTimeEnd = `${this.createTime} 23:59`
      }

      if (this.activityStartTimeArr.length > 1) {
        obj.activityStartTimeStart = this.activityStartTimeArr[0]
        obj.activityStartTimeEnd = this.activityStartTimeArr[1]
      }

      if (this.activityEndTimeArr.length > 1) {
        obj.activityEndTimeStart = this.activityEndTimeArr[0]
        obj.activityEndTimeEnd = this.activityEndTimeArr[1]
      }

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
    },
  },
  mounted() {
    if (this.$route.query.activityId) {
      this.search.activityId = this.$route.query.activityId
    }
    this.getList()
  },
  methods: {
    processItem(row, type) {
      if (type == 1 || type == 2 || type == 4) {
        this.$router.push({
          name: 'fullGiftDetail',
          query: {
            id: row.activityId || 0,
            disabled: type == 4,
            bizLineId: this.$route.query.bizLineId || '',
          },
        })

        return
      }

      if (type === 3) {
        this.$confirm(`确认是否${row.isDisable === 1 ? '启用' : '停用'}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            updateFullGiftItemStatus({
              data: row.activityId,
            })
              .then((res) => {
                if (res.success) {
                  this.$message.success('操作成功')
                  this.getList()
                }
              })
              .catch((err) => console.error(err))
          })
          .catch((err) => console.error(err))
      }
    },
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = Array.isArray(this.search[el]) ? [] : ''
      })

      this.createTime = ''
      this.activityStartTimeArr = []
      this.activityEndTimeArr = []
      this.pageNumber = 1
      this.getList()
    },
    getList() {
      getFullGiftList(this.searchCondition)
        .then((res) => {
          const { content, total } = res.data || {}
          this.tableData = content || []
          this.total = Number(total || '0')
        })
        .catch((err) => console.error(err))
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.pageNumber = val
      this.getList()
    },
    searchList() {
      this.pageNumber = 1
      this.getList()
    },
  },
}
</script>

<style lang="scss" scoped>
.full-gift {
  background-color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 200px;
      margin-right: 10px;
    }

    .select-max {
      width: 430px;
      margin-right: 10px;
    }

    .input {
      width: 220px;
    }

    .date-picker {
      width: 360px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table-ex {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }
}
</style>
